import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  {
    path: "admins",
    loadChildren: () => import("../../components/admins/admins.module").then((m) => m.AdminsModule),
  },
  {
    path: "users",
    loadChildren: () => import("../../components/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "user/:id",
    loadChildren: () => import("../../components/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "services",
    loadChildren: () => import("../../components/services/services.module").then((m) => m.ServicesModule),
  },
  {
    path: "brands",
    loadChildren: () => import("../../components/brands/brands.module").then((m) => m.BrandsModule),
  },
  {
    path: "visits",
    loadChildren: () => import("../../components/visits/visits.module").then((m) => m.VisitsModule),
  },
  {
    path: "addbooking",
    loadChildren: () => import("../../components/addbooking/addbooking.module").then((m) => m.AddbookingModule),
  },
  {
    path: "editbooking/:id",
    loadChildren: () => import("../../components/editbooking/editbooking.module").then((m) => m.EditbookingModule),
  },
  {
    path: "masters",
    loadChildren: () => import("../../components/masters/masters.module").then((m) => m.MastersModule),
  },
  {
    path: "details",
    loadChildren: () => import("../../components/details/details.module").then((m) => m.DetailsModule),
  },
  {
    path: "carbrands",
    loadChildren: () => import("../../components/brands/brands.module").then((m) => m.BrandsModule),
  },
  {
    path: "cars",
    loadChildren: () => import("../../components/cars/cars.module").then((m) => m.CarsModule),
  },
  {
    path: "car/:id",
    loadChildren: () => import("../../components/car/car.module").then((m) => m.CarModule),
  },
  {
    path: "banners",
    loadChildren: () => import("../../components/banners/banners.module").then((m) => m.BannersModule),
  },
  {
    path: "content",
    loadChildren: () => import("../../components/content/content.module").then((m) => m.ContentModule),
  },
  {
    path: "push",
    loadChildren: () => import("../../components/push/push.module").then((m) => m.PushModule),
  },
  {
    path: "reglament",
    loadChildren: () => import("../../components/reglament/reglament.module").then((m) => m.ReglamentModule),
  },
  {
    path: "feedback",
    loadChildren: () => import("../../components/feedback/feedback.module").then((m) => m.FeedbackModule),
  }
];
