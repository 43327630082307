import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { RequestService } from "src/servcs/http-service.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  badgeValue = '';

  constructor(private router: Router, public http: RequestService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }

    this.detectReglaments();
  }

  detectReglaments() {

    this.http.get("get_reglaments").then(async (res) => {
      this.MENUITEMS[6].badgeValue = await res.data.filter(item => item.status == 0).length.toString();
    });

    setTimeout(() => { this.detectReglaments() }, 3000);
  }


  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    { path: "/users", title: "Клієнти", type: "link", bookmark: true },
    { path: "/feedback", title: "Зворотній зв'язок", type: "link", bookmark: true },
    { path: "/services", title: "Послуги", type: "link", bookmark: true },
    { path: "/banners", title: "Банери", type: "link", bookmark: true },
    { path: "/content", title: "Контент", type: "link", bookmark: true },
    { path: "/push", title: "Оповіщення", type: "link", bookmark: true },
    { path: "/reglament", title: "Регламент", type: "link", bookmark: true, badgeValue: this.badgeValue, badgeType: 'warning' },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
